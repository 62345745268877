<style>
.view-full-screen {
  width: 100%; height: 100%;
  position: relative;
}
.view-full-screen img {
  object-fit: scale-down;
}
.view-full-screen .buttons {
  position: absolute;
  left: 0; top: 0; right: 0; bottom: 0;
  color: white; font-size: 35px;
  display: flex; align-items: center;
  pointer-events: none;
}
.view-full-screen .buttons .btn-navigation {
  width: 50px; height: 50px;
  display: flex; align-items: center; justify-content: center;
  cursor: pointer; pointer-events: all;
}
.view-full-screen .buttons .btn-navigation:hover {
  opacity: 0.65;
}
.view-full-screen .buttons .btn-navigation:active {
  opacity: 0.45;
}
.view-full-screen .buttons .btn-navigation.disabled {
  cursor: not-allowed;
  opacity: 0.45;
}
</style>
<div class="view-full-screen">
  <img *ngIf="!isPDF" class="match-parent" [src]="itemUrl" alt="">
  <pdf-viewer *ngIf="isPDF" [src]="itemUrl" style="height: 100%"></pdf-viewer>
  <div class="buttons">
    <div class="btn-navigation back" [ngClass]="{'disabled': !canBack}" (click)="onBtnBack()">
      <i nz-icon nzType="left" nzTheme="outline"></i>
    </div>
    <div class="space"></div>
    <div class="btn-navigation next" [ngClass]="{'disabled': !canNext}" (click)="onBtnNext()">
      <i nz-icon nzType="right" nzTheme="outline"></i>
    </div>
  </div>
</div>
