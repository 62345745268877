<div style="padding-right: 20px; margin-top: -10px; margin-bottom: 10px;">
    <nz-input-group [nzSuffix]="suffixIcon">
        <input nz-input
            #searchKey
            [(ngModel)]="key"
            (keydown)="onKeyDown($event)"
        >
    </nz-input-group>
    <ng-template #suffixIcon>
        <span class="clickable" (click)="onSearch()" nz-icon nzType="search"></span>
    </ng-template>
</div>

<div class="search-result-section">
    <div *ngIf="searchingShipment" class="loading-container">
        <div class="loading-icon">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
    </div>
      
    <div *ngIf="shipments && !shipments?.total" class="not-found">No Order Found!</div>
    <div *ngIf="shipments?.total">
        <div class="search-result-total flex">
            <div class="flex1">Found {{ shipments.total }} Orders</div>
            <div>
                <div *ngIf="shipments?.total > shipments?.count">
                    <a [routerLink]="[routeAdminShipmentList]" [queryParams]="{search: shipments.key}">Show more</a>
                </div>        
            </div>
        </div>

        <div *ngFor="let order of shipments.list_data" class="search-result-item">
            <div class="search-result-title">
                <a [routerLink]="[routeAdminShipmentList, order.id]" class="main-link">
                    Order <code>{{ order.code }}</code> <code>[O-{{ order.warpId }}]</code>
                </a>
                <a target="_blank" [routerLink]="[routeAdminShipmentList, order.id]" class="secondary-link">
                    <span nz-icon nzType="link" nzTheme="outline"></span>
                </a>
            </div>
            <ng-container *ngIf="order.metadata?.shipments">
                <div *ngFor="let shipment of order.metadata.shipments" style="padding-left: 15px;">
                    Shipments <code >{{ shipment.code }} [S-{{ shipment.warpId }}]</code>
                    <ng-container *ngIf="shipment.isCrossDock && shipment.legs?.length">
                        Legs: <code *ngFor="let leg of shipment.legs">{{ leg.code ?? leg.warpId }} </code>
                    </ng-container>
                </div>
            </ng-container>
        </div>
</div>
</div>

<div class="search-result-section">
    <div *ngIf="searchingJob" class="loading-container">
        <div class="loading-icon">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
    </div>

    <div *ngIf="jobs && !jobs?.total" class="not-found">No Job Found!</div>
    <div *ngIf="jobs?.total">
        <div class="search-result-total flex">
            <div class="flex1">Found {{ jobs.total }} Jobs</div>
            <div>
                <div *ngIf="jobs?.total > jobs?.count">
                    <a [routerLink]="[routeAdminDispatchList]" [queryParams]="{search: jobs.key}">Show more</a>
                </div>        
            </div>
        </div>
        <div *ngFor="let job of jobs.list_data" class="search-result-item">
            <div class="search-result-title">
                <a [routerLink]="[routeAdminDispatchList, job.id]" class="main-link">
                    Route <code>{{ job.code }}</code>
                </a>
                <a target="_blank" [routerLink]="[routeAdminDispatchList]" class="secondary-link">
                    <span nz-icon nzType="link" nzTheme="outline"></span>
                </a>
            </div>
        </div>
    </div>
</div>