<style>
.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 90%;
  width: 100%;
}
.gallery-top-no-thumbs {
  height: 100%;
}
.gallery-thumbs {
  height: 10%;
  box-sizing: border-box;
  padding: 4px 0;
}
.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  border: 3px solid white;
  border-radius: 4px;
}
.gallery-thumbs .swiper-slide-thumb-active {
  border-color: rgb(0, 255, 21);
}
.preview-img-large {
  object-fit: scale-down;
}
.preview-img-thumb {
  object-fit: cover;
}
</style>

<div class="swiper-container gallery-top" [ngClass]="{'gallery-top-no-thumbs': !showThumbs}">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let im of listUrl">
      <img class="match-parent preview-img-large" [src]="getImageUrl(im)" alt="">

      <!-- <img class="match-parent preview-img-large swiper-lazy" [attr.data-src]="im" alt="image">
      <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div> -->
    </div>
  </div>
  <div *ngIf="!isMobile" class="swiper-button-next swiper-button-white"></div>
  <div *ngIf="!isMobile" class="swiper-button-prev swiper-button-white"></div>
</div>