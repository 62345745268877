import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

// import { DragDropGridModule } from '@libs/cdk-drag-drop-grid/module';
import { NotificationDispatch } from './notification-dispatch';
import { ScrollTrackerDirective } from './scroller.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DragDropModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzDrawerModule,
    NzFormModule,
    NzCheckboxModule,
    NzSelectModule,
    NzToolTipModule,
    // DragDropGridModule,
  ],
  declarations: [
    NotificationDispatch,
    ScrollTrackerDirective
  ],
  exports: [
    NotificationDispatch,
    ScrollTrackerDirective
  ],
})
export class DrawerModule {
}
