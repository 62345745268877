import { Component, Input } from "@angular/core";
import { BaseComponent } from '@abstract/BaseComponent';
import { NzModalRef } from 'ng-zorro-antd/modal';
import Swiper, { Navigation, Lazy, Zoom } from 'swiper';
import { AttachedFileUtil } from "@services/attached-file-util";
Swiper.use([Navigation, Lazy, Zoom]);

@Component({
  selector: 'dlg-preview-img',
  templateUrl: './dlg-preview-img.html',
  styleUrls: ['./dialogs.scss']
})
export class DlgPreviewImgComponent extends BaseComponent {
  @Input() listUrl = [];
  @Input() currentIndex = 0;
  @Input() showThumbs = false;
  
  constructor(private modal: NzModalRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.currentIndex < 0 || this.currentIndex >= this.listUrl.length) {
      this.currentIndex = 0;
    }
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.initSwiper();
    }, 1);
  }
  
  private initSwiper() {
    let ops = {
      zoom: false,
      lazy: true,
      initialSlide: this.currentIndex,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    };
    let galleryTop = new Swiper('.gallery-top', ops);
  }

  public closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  public getImageUrl(item) {
    if (this.isPdf(item)) {
      let url = AttachedFileUtil.attachedFileUrl(item);
      return `${url}?origin=${window.location.host}`;
    }
    return super.getImageUrl(item);
  }

}

// https://photoswipe.com/
// https://github.com/nolimits4web/swiper / https://swiperjs.com/demos/