import { Input, Directive } from "@angular/core";
import { NzModalRef } from "ng-zorro-antd/modal";
// import { BaseDetail } from "@app/admin/base/detail";
// import { FormUtil } from "@services/form-util";
import { BaseFormItem } from "@app/admin/base/form-item";

/**
 * @description Use with DialogService.openFormDialog() to be a dialog component or non-dialog component
 */
@Directive()
export abstract class BaseFormDialog1<T = any> extends BaseFormItem<T> {
// export abstract class BaseFormDialog1 extends BaseDetail{
  @Input() isDialog: boolean = false;                     // will be assigned to true in DialogService.openFormDialog()
  @Input() getModalRef: () => NzModalRef = () => null;
  @Input() createSuccess: (resp) => void = () => {};      // to notify the guy who has created this dialog
  @Input() updateSuccess: (resp) => void = () => {};      // to notify the guy who has created this dialog
  @Input() closeOnSuccess: boolean = false;
  
  get isCreateNew(): boolean {
    return !this.model || !(<any>this.model)._id;
  }
  get isEditOrCreate(): boolean {
    return this.isCreateNew || this.isEditing;
  }
  get id() {
    if (!this.model) return '';
    return (<any>this.model)._id;
  }
  
  constructor() {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();
  }
  
  protected get nzModal(): NzModalRef {
    return this.getModalRef();
  }
  
  public closeDialog() {
    this.nzModal?.destroy();
  }
  
  protected onCreateSuccess(resp) {
    super.onCreateSuccess(resp);
    this.createSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }
  
  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }
  
  protected goDetailAfterCreated() {
    // do nothing
  }
  
  protected getApiUrl(): string {
    return '';
  }
  
  protected handleCreateDataResponse(resp) {
    // let httpCode = resp.status;
    // let body = resp.body;
    // if (body && body.data) {
    //   this.model = body.data;
    //   this.bindDataModel(this.model);
    // }
    // this.stopProgress();
    this.onCreateSuccess(resp.body);
  }

}