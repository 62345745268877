import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DlgChangePwComponent } from './dlg-change-pw';
import { DlgPreviewImgComponent } from './dlg-preview-img';
import { DlgForgotPw } from './dlg-forgot-pw';
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { ViewFullScreen } from './pod-full-screen';
import { DlgCreateSuccessComponent } from './dlg-create-success';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DragDropModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzModalModule,
    NzFormModule,
    NzCheckboxModule,
    NzSelectModule,
    NzToolTipModule,
    PdfViewerModule,
  ],
  declarations: [
    DlgChangePwComponent,
    DlgPreviewImgComponent,
    DlgForgotPw,
    ViewFullScreen,
    DlgCreateSuccessComponent
  ],
  exports: [
    DlgChangePwComponent,
    DlgForgotPw,
    ViewFullScreen,
    DlgCreateSuccessComponent
  ],
})
export class DialogsModule {
}
