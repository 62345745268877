import { EventEmitter, Type, Injectable } from '@angular/core';
import { NzDrawerService , NzDrawerOptions, NzDrawerRef } from 'ng-zorro-antd/drawer';
import { getInjector } from '@services/index';
import { Utils } from '@services/utils';
import { BaseDrawer } from './base-drawer';
import { BaseFormDrawer } from './base-form';
import { NotificationDispatch } from './notification-dispatch';

// declare let OverlayScrollbars;

@Injectable()
export class DrawerService {
  private drawerService: NzDrawerService;

  constructor() {
    let injector = getInjector();
    this.drawerService = injector.get(NzDrawerService);
  }
  
  public static openFormDrawer<T extends BaseFormDrawer>(component: Type<T>, options?: NzDrawerOptions<T>) {
    let drawerRef;
    let ops: NzDrawerOptions<T> = options || {};
    let wrapClassName = ops.nzWrapClassName || '';
    if (wrapClassName.length > 0) {
      wrapClassName += ' ';
    }
    wrapClassName += 'wrap-drawer';
    ops.nzWrapClassName = wrapClassName;
    ops.nzContent = component;
    ops.nzPlacement = options.nzPlacement || 'right';
    ops.nzContentParams = options.nzContentParams || {};
    ops.nzMaskClosable = options.nzMaskClosable || false;
    ops.nzTitle = options.nzTitle || '';
    ops.nzFooter = options.nzFooter || '';
    ops.nzContentParams.getDrawerRef = function() {
      return drawerRef;
    }
    let injector = getInjector();
    let drawerService = injector.get(NzDrawerService);
    drawerRef = drawerService.create(ops);
  }

  // init nzClosable is disable
  public static openFormDrawer1<T extends BaseFormDrawer>(component: Type<T>, options?: NzDrawerOptions<T>) {
    let drawerRef;
    let defaultOptions: NzDrawerOptions<T> = {nzClosable: false, nzMaskClosable: false, nzKeyboard: false};
    let ops: NzDrawerOptions<T> = Object.assign(defaultOptions, options || {});
    let wrapClassName = ops.nzWrapClassName || '';
    if (wrapClassName.length > 0) {
      wrapClassName += ' ';
    }
    wrapClassName += 'wrap-drawer';
    ops.nzWrapClassName = wrapClassName;
    ops.nzContent = component;
    ops.nzPlacement = options.nzPlacement || 'right';
    ops.nzContentParams = options.nzContentParams || {};
    ops.nzMaskClosable = options.nzMaskClosable || false;
    ops.nzTitle = options.nzTitle || '';
    ops.nzFooter = options.nzFooter || '';
    ops.nzContentParams.getDrawerRef = function() {
      return drawerRef;
    }
    let injector = getInjector();
    let drawerService = injector.get(NzDrawerService);
    drawerRef = drawerService.create(ops);
  }

  public static openDrawer<T extends BaseDrawer>(component: Type<T>, options?: NzDrawerOptions<T>): NzDrawerRef {
    let drawerRef;
    let ops: NzDrawerOptions<T> = options || {};
    let wrapClassName = ops.nzWrapClassName || '';
    if (wrapClassName.length > 0) {
      wrapClassName += ' ';
    }
    wrapClassName += 'wrap-drawer';
    ops.nzWrapClassName = wrapClassName;
    if (component) {
      ops.nzContent = component;
    } else {
      ops.nzContent = options?.nzContent;
    }
    ops.nzPlacement = options?.nzPlacement || 'right';
    ops.nzContentParams = options?.nzContentParams || {};
    ops.nzMaskClosable = options?.nzMaskClosable || false;
    ops.nzTitle = options?.nzTitle || '';
    ops.nzFooter = options?.nzFooter || '';
    ops.nzContentParams.getDrawerRef = function() {
      return drawerRef;
    }
    let injector = getInjector();
    let drawerService = injector.get(NzDrawerService);
    drawerRef = drawerService.create(ops);
    return drawerRef;
  }

  // init nzClosable is disable
  public static openDrawer1<T extends BaseDrawer>(component: Type<T>, options?: NzDrawerOptions<T>) {
    let drawerRef;
    let defaultOptions: NzDrawerOptions<T> = {nzClosable: false, nzMaskClosable: false, nzKeyboard: false};
    let ops: NzDrawerOptions<T> = Object.assign(defaultOptions, options || {});
    let wrapClassName = ops.nzWrapClassName || '';
    if (wrapClassName.length > 0) {
      wrapClassName += ' ';
    }
    wrapClassName += 'wrap-drawer';
    ops.nzWrapClassName = wrapClassName;
    ops.nzContent = component;
    ops.nzPlacement = options.nzPlacement || 'right';
    ops.nzContentParams = options.nzContentParams || {};
    ops.nzMaskClosable = options.nzMaskClosable || false;
    ops.nzTitle = options.nzTitle || '';
    ops.nzFooter = options.nzFooter || '';
    ops.nzContentParams.getDrawerRef = function() {
      return drawerRef;
    }
    let injector = getInjector();
    let drawerService = injector.get(NzDrawerService);
    drawerRef = drawerService.create(ops);
  }

  private handleDrawerOpened(): EventEmitter<any> {
    let afterOpen = new EventEmitter<any>();
    afterOpen.subscribe(() => {
    });
    return afterOpen;
  }

  public notificationDispatch() {
    DrawerService.openDrawer(NotificationDispatch, {});
  }

}