import { Component, Input } from "@angular/core";
import { BaseComponent } from '@abstract/BaseComponent';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AttachedFileUtil } from "@services/attached-file-util";
import { AttachedFile } from "@wearewarp/types/data-model";

@Component({
  selector: 'pod-full-screen',
  templateUrl: './pod-full-screen.html',
  styleUrls: ['./dialogs.scss']
})
export class ViewFullScreen extends BaseComponent {
  @Input() podArr: Array<AttachedFile> = [];
  @Input() currentIndex = 0;
  
  constructor(private modal: NzModalRef) {
    super();
  }

  get podItem() {return this.podArr[this.currentIndex]}
  get canBack(): boolean {return this.currentIndex > 0}
  get canNext(): boolean {return this.currentIndex < this.podArr.length - 1}
  get isPDF() {return super.isPdf(this.podItem)}
  get itemUrl() {return AttachedFileUtil.attachedFileUrl(this.podItem)}

  public closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.currentIndex < 0 || this.currentIndex >= this.podArr.length) {
      this.currentIndex = 0;
    }
  }

  ngAfterViewInit(): void {
  }

  onBtnBack() {
    if (!this.canBack) return;
    this.currentIndex--;
  }

  onBtnNext() {
    if (!this.canNext) return;
    this.currentIndex++;
  }
  
}