import { Component, ElementRef, ViewChild } from '@angular/core';
import { Const } from '@const/Const';
import { KeyLang } from '@locale/index';
import { InputHelper } from '@services/input-helper';
import isEmail from 'validator/lib/isEmail';
import { BaseFormDialog1 } from './base-form-dlg1';
import { MasterData } from '@services/master.data';
import { Utils } from '@services/utils';

@Component({
  selector: '[dlg-forgot-pw]',
  templateUrl: './dlg-forgot-pw.html',
  styleUrls: ['./dlg-forgot-pw.scss', '../../styles/form-v1.scss'],
})
export class DlgForgotPw extends BaseFormDialog1 {
  private resetPwCodeLen = 8;
  txtForgotPwdGuide = '';
  txtSendCode = '';
  txtSetNewPw = '';
  txtRequireNumberCharacters = '';
  txtBackToLogin = '';
  txtResetPwCode = '';
  txtHintNewPw = ''
  txtHintConfirmPw = '';

  isLoading = false;
  // phase 1: nhập email để lấy code
  // phase 2: nhập code để reset password
  phase = 1;

  inputEmail = '';
  inputCode = '';
  inputPw = '';
  inputPwConfirm = '';
  pwHidden = true;
  pwConfirmHidden = true;
  get pwPolicy() { return MasterData.pwPolicyText }

  get canSubmit(): boolean {
    if (this.isLoading) return false;
    switch (this.phase) {
      case 1: return isEmail(this.inputEmail.trim());
      case 2: return this.isPhase2Valid();
    }
  }

  get txtSubmit(): string {
    return this.phase == 1 ? this.txtSendCode : this.txtSetNewPw;
  }

  private isPhase2Valid() {
    if (this.inputCode.length != this.resetPwCodeLen) {
      return false;
    }
    return this.inputPwNewValid && this.inputPwConfirmValid;
  }

  get inputPwNewValid(): boolean {
    return this.inputPw.length > 0;
  }

  get inputPwConfirmValid(): boolean {
    return this.inputPwConfirm && this.inputPwConfirm === this.inputPw;
  }

  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('codeInput') codeInput: ElementRef;
  @ViewChild('pwInput') pwInput: ElementRef;
  @ViewChild('pwConfirmInput') pwConfirmInput: ElementRef;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    (<HTMLElement>this.emailInput.nativeElement).focus();
  }

  setupLanguage() {
    this.txtForgotPwdGuide = this.text(KeyLang.Txt_ForgotPwdGuide);
    this.txtSendCode = this.text(KeyLang.Txt_SendResetPwCode);
    this.txtBackToLogin = this.text(KeyLang.Txt_BackToLogin);
    this.txtSetNewPw = this.text(KeyLang.Txt_ResetNewPw);
    this.txtResetPwCode = this.text(KeyLang.Txt_ResetPwCode);
    this.txtHintNewPw = this.text(KeyLang.Txt_NewPwHint, [`${Const.password_len_min}`, `${Const.password_len_max}`]);
    this.txtHintConfirmPw = this.text(KeyLang.Txt_ConfirmPwHint);
  }

  onKeyUp(event: KeyboardEvent) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    if (this.emailInput && event.target == this.emailInput.nativeElement) {
      this.onBtnSubmit();
    } else if (this.codeInput && event.target == this.codeInput.nativeElement) {
      this.pwInput.nativeElement.focus();
    } else if (this.pwInput && event.target == this.pwInput.nativeElement) {
      this.pwConfirmInput.nativeElement.focus();
    } else if (this.pwConfirmInput && event.target == this.pwConfirmInput.nativeElement) {
      this.onBtnSubmit();
    }
  }

  onBtnSubmit() {
    if (!this.canSubmit) {
      return;
    }
    if (this.phase == 1) {
      this.submit1();
    } else {
      this.submit2();
    }
  }

  private submit1() {
    this.isLoading = true;
    let params = {email: this.inputEmail};
    this.auth.forgotPwStep1(params).subscribe(
      resp => {
        this.txtForgotPwdGuide = resp.message;
        this.resetPwCodeLen = resp.data.code_len;
        this.txtRequireNumberCharacters = `You have to enter ${resp.data.code_len} characters`;
        this.phase = 2;
        this.isLoading = false;
        setTimeout(() => {
          this.codeInput.nativeElement.focus();
        }, 200)
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private submit2() {
    this.isLoading = true;
    let params = {
      email: this.inputEmail,
      code: this.inputCode,
      new_pw: this.inputPw
    };
    this.auth.forgotPwStep2(params).subscribe(
      resp => {
        this.isLoading = false;
        this.showSuccess("Changed password successfully");
        this.stopProgress();
        this.closeDialog();
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  getTextButtonSubmit(phase) {
    if(phase == 1) {
      return this.txtSendCode;
    } 
    if(phase == 2) {
      return this.txtSetNewPw;
    }
  } 

  maskEmail(email) {
    if(!email) return;
    return Utils.maskEmail(email);
  }

  onPasteOtp(event: ClipboardEvent) {
    let text = event.clipboardData.getData('text');
    text = text.replace(/[W-]/g, "");
    setTimeout(() => {
      this.inputCode = text;
    }, 10);
  }
}
