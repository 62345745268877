import { Input, Directive } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

/**
 * @description Use with DrawerService.openDrawer() to be a dialog component or non-dialog component
 */
@Directive()
export class BaseDrawer extends BaseComponent {
  @Input() isDrawer: boolean = false;                     // will be assigned to true in DrawerService.openDrawer()
  @Input() getDrawerRef: () => NzDrawerRef = () => null;
  @Input() value = '';

  protected get nzDrawer(): NzDrawerRef {
    return this.getDrawerRef();
  }
  
  public closeDrawer() {
    this.nzDrawer.close(this.value);
  }
}