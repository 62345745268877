import {
  Component,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '../const/Const';
import { KeyLang } from '@locale/generated/keys';
import { Log } from '@app/services/log';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MasterData } from '@services/master.data';

const TAG = '[DlgChangePw]';

@Component({
  selector: 'dlg-change-pw',
  templateUrl: './dlg-change-pw.html',
  styleUrls: ['./dialogs.scss', '../app.scss']
})
export class DlgChangePwComponent extends BaseComponent {
  txtDlgTitle: string;
  txtOldPw: string;
  txtNewPw: string;
  txtConfirmPw: string;
  txtHintOldPw: string;
  txtHintNewPw: string;
  txtHintConfirmPw: string;

  txtResult: string;

  inputPwOld: string = '';
  inputPwNew: string = '';
  inputPwConfirm: string = '';

  pwOldHidden: boolean = true;
  pwNewHidden: boolean = true;
  pwConfirmHidden: boolean = true;

  private _onProgress = false;
  private _changePwSuccess = false;
  
  @Input() user: any;

  @ViewChild('elemInputPwOld') elemInputPwOld: ElementRef;
  @ViewChild('elemInputPwNew') elemInputPwNew: ElementRef;
  @ViewChild('elemInputPwConfirm') elemInputPwConfirm: ElementRef;

  constructor(private modal: NzModalRef) {
    super();
  }

  private closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  get canClickOK(): boolean {
    if (this.isResetPw) {
      return this.inputPwNewValid && this.inputPwConfirmValid && !this.onProgress;
    } else {
      return this.inputPwOldValid && this.inputPwNewValid && this.inputPwConfirmValid && !this.onProgress;
    }
  }
  get canClickCancel(): boolean {
    return !this.onProgress;
  }
  get inputPwOldValid(): boolean {
    return this.inputPwOld && this.inputPwOld.length > 0;
  }
  get inputPwNewValid(): boolean {
    return this.inputPwNew.length > 0;
  }
  get inputPwConfirmValid(): boolean {
    return this.inputPwConfirm && this.inputPwConfirm === this.inputPwNew;
  }
  get onProgress() {
    return this._onProgress;
  }
  get changePwSuccess() {
    return this._changePwSuccess;
  }
  get isResetPw() {
    return this.user != null && this.user != undefined;
  }
  get pwPolicy() { return MasterData.pwPolicyText }

  setupLanguage() {
    this.txtOldPw = this.text(KeyLang.Txt_OldPw);
    this.txtNewPw = this.text(KeyLang.Txt_NewPw);
    this.txtConfirmPw = this.text(KeyLang.Txt_ConfirmPw);
    this.txtHintOldPw = this.text(KeyLang.Txt_OldPwHint);
    this.txtHintNewPw = this.text(KeyLang.Txt_NewPwHint);
    this.txtHintConfirmPw = this.text(KeyLang.Txt_ConfirmPwHint);
    if (this.user) {
      this.txtDlgTitle = this.text(KeyLang.Txt_ResetPwUser, [this.getFullName(this.user)]);
    } else {
      this.txtDlgTitle = this.text(KeyLang.Txt_ChangePw);
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  cancel() {
    this.closeDialog();
    this.resetState();
  }

  ok() {
    if (this._changePwSuccess) {
      if (this.isResetPw) {
        this.cancel();
      } else {
        this.reLogin();
        this.closeDialog();
      }
      return;
    }
    if (this.isResetPw) {
      this.resetPw();
    } else {
      this.changePw();
    }
  }

  private resetPw() {
    this.txtResult = '';
    this._onProgress = true;
    const params = {
      new_pw: this.inputPwNew
    };
    this.api.POST(Const.APIURI_USERS + '/' + this.user._id + '/reset_pw', params).subscribe(
      resp => {
        this.changePwDone();
      }, err => {
        Log.e(TAG + ' error: ', err);
        this.changePwDone(err);
      }
    );
  }

  private changePw() {
    this.txtResult = '';
    this._onProgress = true;
    const params = {
      old_pw: this.inputPwOld,
      new_pw: this.inputPwNew
    };
    this.auth.changePw(params).subscribe(
      resp => {
        this.changePwDone();
      }, err => {
        Log.e(TAG + ' error: ', err);
        this.changePwDone(err);
      }
    );
  }

  private changePwDone(err = null) {
    this._changePwSuccess = err == null;
    this._onProgress = false;
    if (!err) {
      if (this.isResetPw) {
        this.txtResult = this.text(KeyLang.Txt_ResetPwUserSuccess, [this.user.fullName]);
      } else {
        this.txtResult = this.text(KeyLang.Txt_ChangePwSuccess);
      }
    } else {
      this.txtResult = err.message || 'Change password failed.';
    }
  }

  private reLogin() {
    this.auth.logout();
    this.goLogin();
  }

  private resetState() {
    this._onProgress = false;
    this._changePwSuccess = false;
    this.txtResult = '';
    this.inputPwOld = '';
    this.inputPwNew = '';
    this.inputPwConfirm = '';
  }

  showHidePw(id) {
    switch (id) {
      case 'old':
        this.pwOldHidden = !this.pwOldHidden;
        this.elemInputPwOld.nativeElement.type = this.pwOldHidden ? 'password' : 'text';
        break;
      case 'new':
        this.pwNewHidden = !this.pwNewHidden;
        this.elemInputPwNew.nativeElement.type = this.pwNewHidden ? 'password' : 'text';
        break;
      case 'confirm':
        this.pwConfirmHidden = !this.pwConfirmHidden;
        this.elemInputPwConfirm.nativeElement.type = this.pwConfirmHidden ? 'password' : 'text';
        break;
    }
  }

}
