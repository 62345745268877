<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <b>Forgot Password</b>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<ng-container>
  <ng-container [ngSwitch]="phase">
    <!-- phase 1 -->
    <div *ngSwitchCase="1">
      <div class="email-description">
        We'll send you an email containing a One Time Password (OTP), to authenticate your request.
      </div>
      <div class="email-field">
        <b>Email Address</b>
        <span class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <input class="email-input" nz-input type="email" name="email" #emailInput
          placeholder="The email which you use to log in" [(ngModel)]="inputEmail" (keyup)="onKeyUp($event)">
      </nz-form-item>
    </div>
    <!-- phase2 -->
    <div *ngSwitchCase="2">
      <div class="email-description">
        A 6-characters-length OTP has been sent to your email address <a>{{ maskEmail(inputEmail) }}</a>.
        Please go to your email to copy the OTP then paste it here to reset password (the OTP will expire after 1 hour).
        <br /><br />

        <b>Note:</b> If you don't see any new email in your inbox, please check the Spam/Junk folder.
      </div>

      <div class="otp-field">
        <div class="email-field">
          <b>OTP</b>
          <span class="label-mark-required"></span>
        </div>
        <nz-input-group [nzPrefix]="'W-'">
          <input type="text" #codeInput [(ngModel)]="inputCode" nz-input [placeholder]="txtResetPwCode"
          (keyup)="onKeyUp($event)" (input)="onInputChanged($event, 'otp')" (keypress)="onInputKeyPress($event, 'otp')" (paste)="onPasteOtp($event)" autofocus/>
        </nz-input-group>
      </div>

      <div style="margin: 20px 0; font-style: italic;">{{pwPolicy}}</div>

      <div class="create-pwd">
        <div class="email-field">
          <b>Create Password </b>
          <span class="label-mark-required"></span>
        </div>
        <nz-input-group [nzSuffix]="pwVisible" style="margin-bottom: 15px;">
          <input #pwInput nz-input [type]="pwHidden ? 'password' : 'text'" [placeholder]="txtHintNewPw"
            [(ngModel)]="inputPw" (keyup)="onKeyUp($event)" />
        </nz-input-group>
        <ng-template #pwVisible>
          <i class="fa" [ngClass]="{'fa-eye-slash': pwHidden, 'fa-eye': !pwHidden}" (click)="pwHidden = !pwHidden"></i>
        </ng-template>
      </div>

      <div class="confirm-pwd">
        <div class="email-field">
          <b>Confirm Password </b>
          <span class="label-mark-required"></span>
        </div>
        <nz-input-group [nzSuffix]="pwConfirmVisible" style="margin-bottom: 15px;">
          <input #pwConfirmInput nz-input [type]="pwConfirmHidden ? 'password' : 'text'"
            [placeholder]="txtHintConfirmPw" [(ngModel)]="inputPwConfirm" (keyup)="onKeyUp($event)" />
        </nz-input-group>
        <ng-template #pwConfirmVisible>
          <i class="fa" [ngClass]="{'fa-eye-slash': pwConfirmHidden, 'fa-eye': !pwConfirmHidden}"
            (click)="pwConfirmHidden = !pwConfirmHidden"></i>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template [nzModalFooter]>
  <button nz-button class="carrier-btn" nzType="primary" nzShape="round" [disabled]="!canSubmit" (click)="onBtnSubmit()"
    [nzLoading]="onProgress">
    {{getTextButtonSubmit(phase)}}
  </button>
  <button nz-button class="back-btn" nzShape="round" nzType="default" (click)="closeDialog()">Close</button>
</ng-template>