import { Input, Directive } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { NzModalRef } from "ng-zorro-antd/modal";

/**
 * @description Use with DialogService.openDialog() to be a dialog component or non-dialog component
 */
@Directive()
export class BaseDialog extends BaseComponent {
  @Input() isDialog: boolean = false;                     // will be assigned to true in DialogService.openDialog()
  @Input() getModalRef: () => NzModalRef = () => null;
  
  protected get nzModal(): NzModalRef {
    return this.getModalRef();
  }
  
  public closeDialog() {
    this.nzModal?.destroy();
  }
}