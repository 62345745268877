<style type="text/css">
  .model-btns {
    display: flex; flex-direction: row; align-items: center; justify-content: flex-end;
  }
  .row-input {
    margin-bottom: 20px;
  }
  .row-text1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .result-message {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: red;
  }
  .success {
    color: green;
  }
  button:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
  .dlg-change-pwd {
    max-width: 450px;
  }
  </style>
  
  <div>
    <div class="dlg-title pull-left" style="margin-bottom: 20px;">{{ txtDlgTitle }}</div>
    
    <div>
      <ng-container *ngIf="!changePwSuccess">
        <div style="margin-bottom: 20px; font-style: italic;">{{pwPolicy}}</div>

        <div *ngIf="!isResetPw" class="row-input">
          <div>
            <nz-input-group [nzSuffix]="oldPwVisible">
              <input #elemInputPwOld nz-input [type]="pwOldHidden ? 'password' : 'text'" [placeholder]="txtHintOldPw" [(ngModel)]="inputPwOld"/>
            </nz-input-group>
            <ng-template #oldPwVisible>
              <i nz-icon [nzType]="pwOldHidden ? 'eye-invisible' : 'eye'" (click)="pwOldHidden = !pwOldHidden"></i>
            </ng-template>
          </div>
        </div>
  
        <div class="row-input">
          <div>
            <nz-input-group [nzSuffix]="newPwVisible">
              <input #elemInputPwNew nz-input [type]="pwNewHidden ? 'password' : 'text'"  [placeholder]="txtHintNewPw" [(ngModel)]="inputPwNew"/>
            </nz-input-group>
            <ng-template #newPwVisible>
              <i nz-icon [nzType]="pwNewHidden ? 'eye-invisible' : 'eye'" (click)="pwNewHidden = !pwNewHidden"></i>
            </ng-template>
          </div>
        </div>
  
        <div class="row-input">
          <div>
            <nz-input-group [nzSuffix]="confirmPwVisible">
              <input #elemInputPwConfirm nz-input [type]="pwConfirmHidden ? 'password' : 'text'"  [placeholder]="txtHintConfirmPw" [(ngModel)]="inputPwConfirm"/>
            </nz-input-group>
            <ng-template #confirmPwVisible>
              <i nz-icon [nzType]="pwConfirmHidden ? 'eye-invisible' : 'eye'" (click)="pwConfirmHidden = !pwConfirmHidden"></i>
            </ng-template>
          </div>
        </div>
      </ng-container>
  
      <div *ngIf="txtResult" class="result-message" [ngClass]="{'success': changePwSuccess}">
        <div style="white-space: pre-line;">
          {{ txtResult }}
        </div>
      </div>
  
    </div>
  
    <div class="model-btns">
      <button nz-button nzType="default" *ngIf="!changePwSuccess" style="width: 110px;" [disabled]="!canClickCancel" (click)="cancel()">{{ txtCancel }}</button>
      <button nz-button nzType="primary" style="margin-left: 10px; width: 110px;" [disabled]="!canClickOK" (click)="ok()">OK
        <i *ngIf="onProgress" nz-icon nzType="loading" nzTheme="outline" style="margin-left: 10px;"></i>
      </button>
    </div>
  
  </div>
  