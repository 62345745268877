import { EventEmitter, Type, Injectable, TemplateRef } from '@angular/core';
import { NzModalService, ModalOptions, NzModalRef } from 'ng-zorro-antd/modal';
import { getInjector } from '@services/index';
import { Utils } from '@services/utils';
import { DlgChangePwComponent } from './dlg-change-pw';
import { BaseDialog } from './base-dlg';
import { BaseFormDialog1 } from './base-form-dlg1';
import { DlgPreviewImgComponent } from './dlg-preview-img';
import { DlgForgotPw } from './dlg-forgot-pw';
import { ViewFullScreen } from './pod-full-screen';
import { AttachedFile } from '@wearewarp/types/data-model';
import { ConfirmDeleteOptions } from '@app/interfaces';

interface OpenDialogOptions<T> extends ModalOptions<T> {
  replaceWrapClassName?: boolean
}

/**
 * @deprecated use ModalHelper (from @wearewarp/ng-antd) instead
 */
@Injectable()
export class DialogService {
  private modalService: NzModalService;

  constructor() {
    let injector = getInjector();
    this.modalService = injector.get(NzModalService);
  }

  public static openFormDialog1<T extends BaseFormDialog1>(component: Type<T>, options?: ModalOptions<T>) {
    let modalRef;
    let defaultOptions: ModalOptions<T> = {nzClosable: false, nzMaskClosable: false, nzKeyboard: false};
    let ops: ModalOptions<T> = Object.assign(defaultOptions, options || {});
    let className = ops.nzClassName || '';
    if (className.length > 0) {
      className += ' ';
    }
    className += 'modal-admin';
    ops.nzClassName = className;
    let wrapClassName = ops.nzWrapClassName || '';
    if (wrapClassName.length > 0) {
      wrapClassName += ' ';
    }
    ops.nzWrapClassName = wrapClassName;
    ops.nzContent = component;
    if (ops.nzFooter === undefined) {
      ops.nzFooter = null;
    }
    ops.nzComponentParams = ops.nzComponentParams || {};
    ops.nzComponentParams.getModalRef = function() {
      return modalRef;
    }
    ops.nzComponentParams.isDialog = true;
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalRef = modalService.create(ops);
    return modalRef
  }

  public static openDialog<T extends BaseDialog>(component: Type<T>, options?: OpenDialogOptions<T>): NzModalRef {
    let modalRef;
    let ops: OpenDialogOptions<T> = options || {};
    let className = ops.nzClassName || '';
    if (className.length > 0) {
      className += ' ';
    }
    className += 'modal-admin';
    ops.nzClassName = className;
    let wrapClassName;
    if (ops.replaceWrapClassName) {
      wrapClassName = ops.nzWrapClassName;
    } else {
      wrapClassName = ops.nzWrapClassName || '';
      if (wrapClassName.length > 0) {
        wrapClassName += ' ';
      }
      wrapClassName += 'vertical-center-modal';
    }
    ops.nzWrapClassName = wrapClassName;
    if (component) {
      ops.nzContent = component;
    } else {
      ops.nzContent = ops.nzContent;
    }
    if (ops.nzFooter === undefined) {
      ops.nzFooter = null;
    }
    ops.nzComponentParams = ops.nzComponentParams || {};
    ops.nzComponentParams.getModalRef = function() {
      return modalRef;
    }
    ops.nzComponentParams.isDialog = true;
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalRef = modalService.create(ops);
    return modalRef;
  }

  public static openDialog1<T extends BaseDialog>(component: Type<T>, options?: OpenDialogOptions<T>): NzModalRef {
    let modalRef;
    let defaultOptions: ModalOptions<T> = {nzClosable: false, nzMaskClosable: false, nzKeyboard: false};
    let ops: OpenDialogOptions<T> = Object.assign(defaultOptions, options || {});
    let className = ops.nzClassName || '';
    if (className.length > 0) {
      className += ' ';
    }
    className += 'modal-admin';
    ops.nzClassName = className;
    let wrapClassName;
    if (ops.replaceWrapClassName) {
      wrapClassName = ops.nzWrapClassName;
    } else {
      wrapClassName = ops.nzWrapClassName || '';
      if (wrapClassName.length > 0) {
        wrapClassName += ' ';
      }
      wrapClassName += 'vertical-center-modal';
    }
    ops.nzWrapClassName = wrapClassName;
    if (component) {
      ops.nzContent = component;
    } else {
      ops.nzContent = ops.nzContent;
    }
    if (ops.nzFooter === undefined) {
      ops.nzFooter = null;
    }
    ops.nzComponentParams = ops.nzComponentParams || {};
    ops.nzComponentParams.getModalRef = function() {
      return modalRef;
    }
    ops.nzComponentParams.isDialog = true;
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalRef = modalService.create(ops);
    return modalRef;
  }

  public changePwd(user = null) {
    let className = 'modal-admin';
    this.modalService.create({
      nzContent: DlgChangePwComponent,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzClassName: className,
      nzWrapClassName: 'vertical-center-modal',
      nzAfterOpen: this.handleModalOpened(),
      nzComponentParams: {
        user: user
      }
    });
  }

  private handleModalOpened(): EventEmitter<any> {
    let afterOpen = new EventEmitter<any>();
    afterOpen.subscribe(() => {
      // OverlayScrollbars(document.querySelector('.ant-modal-wrap'), { });
    });
    return afterOpen;
  }
  
  public previewImgs(listUrls: Array<string>, currentIndex: number = 0) {
    return DialogService.previewImgs(listUrls, currentIndex);
  }

  public static previewImgs(listUrls: Array<any>, currentIndex: number = 0) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.create({
      nzContent: DlgPreviewImgComponent,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzKeyboard: false,    // press esc key to close
      nzClassName: 'modal-preview-img modal-fullscreen',
      // nzWrapClassName: 'vertical-center-modal',
      // nzAfterOpen: this.handleAfterOpen(),
      // nzAfterClose: this.handleAfterClose(),
      nzComponentParams: {
        listUrl: listUrls,
        currentIndex: currentIndex
      }
    });
  }

  public static forgotPw() {
    this.openFormDialog1(DlgForgotPw, {
      nzCentered: true,
      nzKeyboard: false,
      nzMaskClosable: false,
    });
  }

  public static preview(podArr: Array<AttachedFile>, index: number = 0) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.create({
      nzContent: ViewFullScreen,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzKeyboard: false,    // press esc key to close
      nzClassName: 'modal-preview-img modal-fullscreen',
      nzComponentParams: {
        podArr: podArr,
        currentIndex: index
      }
    });
  }
  public static previewPdfPodTransparent(podArr: Array<AttachedFile>, index: number = 0) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.create({
      nzContent: ViewFullScreen,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzKeyboard: false,    // press esc key to close
      nzClassName: 'modal-preview-img modal-preview-img-transparent modal-fullscreen',
      nzComponentParams: {
        podArr: podArr,
        currentIndex: index,
      }
    });
  }

  public static showDialog(message: string | TemplateRef<any>, onOK = () => { }) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    modalService.create({
      nzContent: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: onOK,
      nzCancelText: null
    });
  }

  public static comingSoon() {
    this.showDialog('Coming soon');
  }

  public static confirmDeletion(ops: ConfirmDeleteOptions) {
    let injector = getInjector();
    let modalService = injector.get(NzModalService);
    let fnCancel = ops.fnCancel;
    if (!Utils.isFunction(fnCancel)) {
      fnCancel = () => {
      }
    }
    modalService.confirm({
      nzTitle: ops.message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: ops.center ?? true,
      nzOkText: ops.txtBtnOk || 'Delete',
      nzOnOk: ops.fnOk,
      nzOkDanger: true,
      nzCancelText: ops.txtBtnCancel || 'Cancel',
      nzOnCancel: fnCancel
    });
  }

}